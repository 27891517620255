.record[data-v-071c1702] {
  font-size: 14px;
  color: #333;
  font-weight: 600;
  margin-bottom: 10px;
}
.record[data-v-071c1702]::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}
[data-v-071c1702] .el-alert__title {
  letter-spacing: 1px;
}
.hint[data-v-071c1702] {
  background-image: url(../img/aimodal.e5856c2b.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-bottom: 10px;
  padding: 15px 35px;
}
.hint_title[data-v-071c1702] {
  font-size: 26px;
  font-weight: 700;
  background-image: -webkit-linear-gradient(left, #000, #0a5baa, #0f39a5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 5px;
  border-bottom: 1px solid #4279b1;
  letter-spacing: 3px;
  display: inline-block;
}
.hint_content[data-v-071c1702] {
  display: flex;
  align-items: center;
  margin-top: 10px;
  color: #3c3c3d;
}
.hint_content span[data-v-071c1702] {
  margin-left: 5px;
}
.set_style[data-v-071c1702] {
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
  color: var(--themeColor, #17a2b8);
  margin-top: 2px;
}
[data-v-071c1702] .el-alert--warning.is-dark {
  background-color: #304456 !important;
}
.hint_btn[data-v-071c1702] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.hint_set[data-v-071c1702] {
  text-decoration: underline;
  cursor: pointer;
}