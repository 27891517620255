.right_title[data-v-9e5d6778] {
  width: 100%;
  margin: 0 auto;
}
.right_title h5[data-v-9e5d6778] {
  font-weight: normal;
}
.right_title p[data-v-9e5d6778] {
  font-size: 14px;
  color: red !important;
  text-align: left !important;
}
.item_radio[data-v-9e5d6778], .one_item[data-v-9e5d6778] {
  width: 100%;
  padding-left: 30px;
  height: 25px;
}
.item_radio2[data-v-9e5d6778] {
  width: 100%;
  padding-left: 60px;
}
.item_lists[data-v-9e5d6778] {
  width: 100%;
  padding-left: 60px;
}
.el-radio-group[data-v-9e5d6778] {
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
  font-size: 0;
}
.none-border p[data-v-9e5d6778] {
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #eee;
  text-indent: 1em;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.showTemplateName[data-v-9e5d6778] {
  margin: -12px 0px 10px 0px;
  font-size: 15px;
}
.showTemplateName-item[data-v-9e5d6778] {
  margin: 8px;
}
[data-v-9e5d6778] .el-tag .el-tag__close {
  color: #f90707 !important;
}
[data-v-9e5d6778] .el-checkbox {
  color: var(--themeColor, #17a2b8) !important;
  border-color: var(--themeColor, #17a2b8) !important;
}
[data-v-9e5d6778] .el-checkbox.is-bordered.is-checked {
  border-color: var(--themeColor, #17a2b8) !important;
}
[data-v-9e5d6778] .el-checkbox__inner {
  border-color: var(--themeColor, #17a2b8) !important;
}